import React from 'react'
import { withTranslation } from 'react-i18next'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'

const TutorialPage = ({ t }) => {
  return (
    <Layout>
      <SEO title={t('tutorial.title')} keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title" dangerouslySetInnerHTML={{ __html: t('tutorial.title') }}></h1>

          <div className="video-responsive mb-32">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/Rz5IXa22vOA?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
          </div>

          <div className="video-responsive mb-32">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/BHApduUq0BE?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
          </div>

          <div className="video-responsive">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/lHwmBpdR9ak?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
          </div>
        </div>
			</div>
    </Layout>
  )
}

export default withTranslation()(TutorialPage)
